export const state = () => ({
  showSearch: false,
  showMobileNav: false,
})

const closeSearchModal = (e) => {
  if (e.key === 'Escape') {
    // eslint-disable-next-line
    $nuxt.$store.commit('nav/closeSearch')
  }
}

export const mutations = {
  openSearch(state) {
    state.showSearch = true

    state.showMobileNav = false

    document.body.classList.add('overflow-hidden')

    document.addEventListener('keydown', closeSearchModal)
  },

  closeSearch(state) {
    state.showSearch = false

    document.body.classList.remove('overflow-hidden')

    document.removeEventListener('keydown', closeSearchModal)
  },

  openMobileNav(state) {
    state.showMobileNav = true

    state.showSearch = false

    document.body.classList.add('overflow-hidden')
  },

  closeMobileNav(state) {
    state.showMobileNav = false

    document.body.classList.remove('overflow-hidden')
  },
}

export const getters = {
  showSearch: (state) => state.showSearch,
  showMobileNav: (state) => state.showMobileNav,
}
