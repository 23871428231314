
export default {
  computed: {
    enabled() {
      return this.$auth.$state.priceInclTax
    },
  },

  methods: {
    toggle() {
      this.$store.commit('global/togglePriceInclTax')
    },
  },
}
