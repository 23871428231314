
import { mapGetters } from 'vuex'
import {
  Popover,
  PopoverPanel,
  PopoverGroup,
  PopoverButton,
} from '@teamnovu/utils'

export default {
  components: {
    Popover,
    PopoverPanel,
    PopoverGroup,
    PopoverButton,
  },

  computed: {
    ...mapGetters('global', ['shopNavigation']),
  },
}
