import algoliasearch from 'algoliasearch/lite'

export const state = () => ({
  query: '',
  pages: null,
  products: null,
  categories: null,
})

export const actions = {
  async searchIndexes({ commit }, searchQuery) {
    commit('setQuery', searchQuery)

    if (!searchQuery) {
      commit('reset')

      return
    }

    const searchClient = algoliasearch(
      this.$config.algolia.appId,
      this.$config.algolia.apiKey
    )

    const queryProduct = {
      indexName: this.$config.algolia.index.product,
      query: searchQuery,
      params: {
        hitsPerPage: 16,
      },
    }
    const queryWebsite = {
      indexName: this.$config.algolia.index.website,
      query: searchQuery,
      params: {
        hitsPerPage: 4,
      },
    }
    const queryCategory = {
      indexName: this.$config.algolia.index.category,
      query: searchQuery,
      params: {
        hitsPerPage: 4,
      },
    }

    const {
      results: [products, pages, categories],
    } = await searchClient.multipleQueries([
      queryProduct,
      queryWebsite,
      queryCategory,
    ])

    commit('setPages', pages)
    commit('setProducts', products)
    commit('setCategories', categories)
  },
}

export const mutations = {
  reset(state) {
    state.pages = null
    state.products = null
    state.categories = null
  },

  setQuery(state, value) {
    state.query = value
  },

  setPages(state, value) {
    state.pages = value
  },

  setProducts(state, value) {
    state.products = value
  },

  setCategories(state, value) {
    state.categories = value
  },
}

export const getters = {
  query: (state) => state.query,
  pages: (state) => state.pages,
  products: (state) => state.products,
  categories: (state) => state.categories,
}
