export const state = () => ({
  addresses: [],
})

export const actions = {
  loginSuccessfull({ dispatch }) {
    this.$graphql.cms.setHeaders({
      Authorization: this.$auth.strategy.token.get(),
    })

    if (this.$auth.$storage.getUniversal('guest_id')) {
      dispatch('cart/linkGuestCart', null, { root: true })
    } else {
      dispatch('cart/getCart', null, { root: true })
    }
  },

  async logout({ commit }) {
    this.$graphql.cms.setHeaders({ authorization: null })

    await this.$auth.logout()

    commit('global/setPriceInclTax', true, { root: true })

    commit('cart/setCart', {}, { root: true })
  },

  setAddresses({ commit }, addresses) {
    commit('setAddresses', addresses)
  },

  async setSelectedBuilding({ dispatch }, building) {
    await this.$axios.$post(`/user/preferences/building/${building.id}`)
    this.$auth.fetchUser()
    dispatch('cart/getCart', null, { root: true })
  },
}

export const mutations = {
  setAddresses(state, addresses) {
    state.addresses = addresses
  },
}

export const getters = {
  addresses: (state) => state.addresses,
}
