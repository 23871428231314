
export default {
  props: {
    nav: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    visible() {
      return (
        this.$auth.loggedIn &&
        this.$auth.user.availableBuildings &&
        this.$auth.user.availableBuildings.length > 1
      )
    },

    selectedBuilding() {
      let building =
        this.$auth.user.selectedBuilding ||
        this.$auth.user.availableBuildings[0]

      if (this.nav && building) {
        building = {
          id: building.id,
          name: (building.name || '').substr(0, 18).trim(),
        }
      }

      return building
    },
  },

  methods: {
    async select(building) {
      await this.$store.dispatch('user/setSelectedBuilding', building)
    },
  },
}
