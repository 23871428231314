
export default {
  data() {
    return {
      showBanner: false,
    }
  },

  mounted() {
    const cookiesAccepted = localStorage.getItem('CookieAccepted')

    if (!cookiesAccepted) {
      this.showBanner = true
    } else {
      this.grantConsent()
    }
  },

  methods: {
    accepted() {
      localStorage.setItem('CookieAccepted', 'true')
      this.showBanner = false
      this.grantConsent()
    },

    grantConsent() {
      window.gtag('consent', 'update', {
        personalization_storage: 'granted',
        security_storage: 'granted',
        functionality_storage: 'granted',
        analytics_storage: 'granted',
      })
    },
  },
}
