
export default {
  props: {
    to: {
      type: String,
      default: '/',
    },

    text: {
      type: String,
      default: '/',
    },

    flip: {
      type: Boolean,
      default: false,
    },
  },
}
