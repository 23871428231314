
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      showStickyCart: false,
    }
  },

  computed: {
    ...mapGetters('global', ['metaNavigation', 'socialMedia']),
  },

  mounted() {
    window.addEventListener('scroll', () => {
      // Toggle sticky cart
      if (window.scrollY <= 56) {
        this.showStickyCart = false
      } else {
        this.showStickyCart = true
      }
    })
  },
}
