import { render, staticRenderFns } from "./MainNavigationMobile.vue?vue&type=template&id=58a846de"
import script from "./MainNavigationMobile.vue?vue&type=script&lang=js"
export * from "./MainNavigationMobile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLink: require('/vercel/path0/components/shared/AppLink.vue').default,BuildingSelector: require('/vercel/path0/components/shared/navigation/BuildingSelector.vue').default,TaxToggle: require('/vercel/path0/components/shared/navigation/TaxToggle.vue').default,NavSearch: require('/vercel/path0/components/shared/navigation/NavSearch.vue').default,ShoppingCartIcon: require('/vercel/path0/components/icons/ShoppingCartIcon.vue').default,ShoppingCartCounter: require('/vercel/path0/components/shared/ShoppingCartCounter.vue').default,MenuCloseIcon: require('/vercel/path0/components/icons/MenuCloseIcon.vue').default,MenuBurgerIcon: require('/vercel/path0/components/icons/MenuBurgerIcon.vue').default,ChevronRightIcon: require('/vercel/path0/components/icons/ChevronRightIcon.vue').default})
