
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      menu: [],
    }
  },

  computed: {
    ...mapGetters('global', ['shopNavigation']),
  },

  methods: {
    toggle(index) {
      this.menu[index].show = !this.menu[index].show
    },

    state(index) {
      if (!this.menu[index]) {
        this.menu[index] = { show: false }
      }
      return this.menu[index]
    },
  },
}
