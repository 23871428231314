
export default {
  data() {
    return {
      show: false,
    }
  },

  mounted() {
    window.addEventListener('scroll', this.handleButtonVisibility)
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleButtonVisibility)
  },

  methods: {
    handleButtonVisibility(event) {
      if (window.scrollY <= 700) {
        this.show = false
      } else {
        this.show = true
      }
    },

    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
}
