import { getGlobals } from '~/graphql/queries.graphql'

export const state = () => ({
  navs: [],
  dimensionUnits: [],
  footerSettings: {},
})

export const mutations = {
  setNavigations(state, value) {
    state.navs = value
  },

  setDimensionUnits(state, value) {
    state.dimensionUnits = value
  },

  setFooterSettings(state, value) {
    state.footerSettings = value
  },

  setSocialMedia(state, value) {
    state.socialMedia = value
  },

  setPriceInclTax(state, value) {
    this.$auth.$storage.setUniversal('priceInclTax', value)
  },

  togglePriceInclTax() {
    const tax = this.$auth.$storage.getUniversal('priceInclTax')

    this.$auth.$storage.setUniversal('priceInclTax', !tax)
  },
}

export const actions = {
  async loadGlobals({ commit }) {
    const response = await this.$graphql.cms.request(getGlobals)

    const footerSettings = response.globalSets.find(
      (global) => global.handle === 'footer_settings'
    )

    const socialMedia = response.globalSets.find(
      (global) => global.handle === 'social_media'
    )

    commit('setNavigations', response.navs)
    commit('setFooterSettings', footerSettings)
    commit('setSocialMedia', socialMedia)
  },

  async loadDimensionUnits({ commit }) {
    const { data: dimensionUnits } = await this.$axios.get('/dimension-units')

    commit('setDimensionUnits', dimensionUnits)
  },
}

export const getters = {
  navs: (state) => state.navs,
  dimensionUnits: (state) => state.dimensionUnits,
  footerSettings: (state) => state.footerSettings,
  socialMedia: (state) => state.socialMedia,
  footerNavigation: (state) => {
    return state.navs.find((nav) => nav.handle === 'footer_navigation')?.tree[0]
  },
  shopNavigation: (state) => {
    return state.navs.find((nav) => nav.handle === 'shop_navigation')?.tree
  },
  metaNavigation: (state) => {
    return state.navs.find((nav) => nav.handle === 'meta_navigation')?.tree
  },
  footerMetaNavigation: (state) => {
    return state.navs.find((nav) => nav.handle === 'footer_meta_navigation')
      ?.tree
  },
  priceInclTax: (state) => state.priceInclTax,
}
