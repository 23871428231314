export const HomeSlider = () => import('../../components/shared/home/HomeSlider.vue' /* webpackChunkName: "components/home-slider" */).then(c => wrapFunctional(c.default || c))
export const HomeSliderBlogPost = () => import('../../components/shared/home/HomeSliderBlogPost.vue' /* webpackChunkName: "components/home-slider-blog-post" */).then(c => wrapFunctional(c.default || c))
export const HomeSliderImage = () => import('../../components/shared/home/HomeSliderImage.vue' /* webpackChunkName: "components/home-slider-image" */).then(c => wrapFunctional(c.default || c))
export const HomeSliderProduct = () => import('../../components/shared/home/HomeSliderProduct.vue' /* webpackChunkName: "components/home-slider-product" */).then(c => wrapFunctional(c.default || c))
export const ScrollDownButton = () => import('../../components/shared/home/ScrollDownButton.vue' /* webpackChunkName: "components/scroll-down-button" */).then(c => wrapFunctional(c.default || c))
export const FooterNavigation = () => import('../../components/shared/footer/FooterNavigation.vue' /* webpackChunkName: "components/footer-navigation" */).then(c => wrapFunctional(c.default || c))
export const FooterNavigationMain = () => import('../../components/shared/footer/FooterNavigationMain.vue' /* webpackChunkName: "components/footer-navigation-main" */).then(c => wrapFunctional(c.default || c))
export const FooterNavigationMenu = () => import('../../components/shared/footer/FooterNavigationMenu.vue' /* webpackChunkName: "components/footer-navigation-menu" */).then(c => wrapFunctional(c.default || c))
export const FooterNavigationMeta = () => import('../../components/shared/footer/FooterNavigationMeta.vue' /* webpackChunkName: "components/footer-navigation-meta" */).then(c => wrapFunctional(c.default || c))
export const AccountNavigation = () => import('../../components/shared/account/AccountNavigation.vue' /* webpackChunkName: "components/account-navigation" */).then(c => wrapFunctional(c.default || c))
export const OrderPagination = () => import('../../components/shared/account/OrderPagination.vue' /* webpackChunkName: "components/order-pagination" */).then(c => wrapFunctional(c.default || c))
export const BuildingSelector = () => import('../../components/shared/navigation/BuildingSelector.vue' /* webpackChunkName: "components/building-selector" */).then(c => wrapFunctional(c.default || c))
export const MainNavigationDesktop = () => import('../../components/shared/navigation/MainNavigationDesktop.vue' /* webpackChunkName: "components/main-navigation-desktop" */).then(c => wrapFunctional(c.default || c))
export const MainNavigationDesktopItems = () => import('../../components/shared/navigation/MainNavigationDesktopItems.vue' /* webpackChunkName: "components/main-navigation-desktop-items" */).then(c => wrapFunctional(c.default || c))
export const MainNavigationMobile = () => import('../../components/shared/navigation/MainNavigationMobile.vue' /* webpackChunkName: "components/main-navigation-mobile" */).then(c => wrapFunctional(c.default || c))
export const NavSearch = () => import('../../components/shared/navigation/NavSearch.vue' /* webpackChunkName: "components/nav-search" */).then(c => wrapFunctional(c.default || c))
export const NavSearchCategory = () => import('../../components/shared/navigation/NavSearchCategory.vue' /* webpackChunkName: "components/nav-search-category" */).then(c => wrapFunctional(c.default || c))
export const NavSearchPage = () => import('../../components/shared/navigation/NavSearchPage.vue' /* webpackChunkName: "components/nav-search-page" */).then(c => wrapFunctional(c.default || c))
export const NavSearchProduct = () => import('../../components/shared/navigation/NavSearchProduct.vue' /* webpackChunkName: "components/nav-search-product" */).then(c => wrapFunctional(c.default || c))
export const TaxToggle = () => import('../../components/shared/navigation/TaxToggle.vue' /* webpackChunkName: "components/tax-toggle" */).then(c => wrapFunctional(c.default || c))
export const ArrowDownIcon = () => import('../../components/icons/ArrowDownIcon.vue' /* webpackChunkName: "components/arrow-down-icon" */).then(c => wrapFunctional(c.default || c))
export const ArrowLeftIcon = () => import('../../components/icons/ArrowLeftIcon.vue' /* webpackChunkName: "components/arrow-left-icon" */).then(c => wrapFunctional(c.default || c))
export const ArrowUpIcon = () => import('../../components/icons/ArrowUpIcon.vue' /* webpackChunkName: "components/arrow-up-icon" */).then(c => wrapFunctional(c.default || c))
export const BillIcon = () => import('../../components/icons/BillIcon.vue' /* webpackChunkName: "components/bill-icon" */).then(c => wrapFunctional(c.default || c))
export const ChevronRightIcon = () => import('../../components/icons/ChevronRightIcon.vue' /* webpackChunkName: "components/chevron-right-icon" */).then(c => wrapFunctional(c.default || c))
export const CornerIcon = () => import('../../components/icons/CornerIcon.vue' /* webpackChunkName: "components/corner-icon" */).then(c => wrapFunctional(c.default || c))
export const DeliveryIcon = () => import('../../components/icons/DeliveryIcon.vue' /* webpackChunkName: "components/delivery-icon" */).then(c => wrapFunctional(c.default || c))
export const DownloadIcon = () => import('../../components/icons/DownloadIcon.vue' /* webpackChunkName: "components/download-icon" */).then(c => wrapFunctional(c.default || c))
export const FileIcon = () => import('../../components/icons/FileIcon.vue' /* webpackChunkName: "components/file-icon" */).then(c => wrapFunctional(c.default || c))
export const FilterIcon = () => import('../../components/icons/FilterIcon.vue' /* webpackChunkName: "components/filter-icon" */).then(c => wrapFunctional(c.default || c))
export const HomeIcon = () => import('../../components/icons/HomeIcon.vue' /* webpackChunkName: "components/home-icon" */).then(c => wrapFunctional(c.default || c))
export const LinkIcon = () => import('../../components/icons/LinkIcon.vue' /* webpackChunkName: "components/link-icon" */).then(c => wrapFunctional(c.default || c))
export const MailIcon = () => import('../../components/icons/MailIcon.vue' /* webpackChunkName: "components/mail-icon" */).then(c => wrapFunctional(c.default || c))
export const MastercardIcon = () => import('../../components/icons/MastercardIcon.vue' /* webpackChunkName: "components/mastercard-icon" */).then(c => wrapFunctional(c.default || c))
export const MenuBurgerIcon = () => import('../../components/icons/MenuBurgerIcon.vue' /* webpackChunkName: "components/menu-burger-icon" */).then(c => wrapFunctional(c.default || c))
export const MenuCloseIcon = () => import('../../components/icons/MenuCloseIcon.vue' /* webpackChunkName: "components/menu-close-icon" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodsIcon = () => import('../../components/icons/PaymentMethodsIcon.vue' /* webpackChunkName: "components/payment-methods-icon" */).then(c => wrapFunctional(c.default || c))
export const PaypalIcon = () => import('../../components/icons/PaypalIcon.vue' /* webpackChunkName: "components/paypal-icon" */).then(c => wrapFunctional(c.default || c))
export const PayrexxIcon = () => import('../../components/icons/PayrexxIcon.vue' /* webpackChunkName: "components/payrexx-icon" */).then(c => wrapFunctional(c.default || c))
export const PhoneIcon = () => import('../../components/icons/PhoneIcon.vue' /* webpackChunkName: "components/phone-icon" */).then(c => wrapFunctional(c.default || c))
export const PlusIcon = () => import('../../components/icons/PlusIcon.vue' /* webpackChunkName: "components/plus-icon" */).then(c => wrapFunctional(c.default || c))
export const SearchIcon = () => import('../../components/icons/SearchIcon.vue' /* webpackChunkName: "components/search-icon" */).then(c => wrapFunctional(c.default || c))
export const ServiceIcon = () => import('../../components/icons/ServiceIcon.vue' /* webpackChunkName: "components/service-icon" */).then(c => wrapFunctional(c.default || c))
export const ShoppingCartIcon = () => import('../../components/icons/ShoppingCartIcon.vue' /* webpackChunkName: "components/shopping-cart-icon" */).then(c => wrapFunctional(c.default || c))
export const SpinIcon = () => import('../../components/icons/SpinIcon.vue' /* webpackChunkName: "components/spin-icon" */).then(c => wrapFunctional(c.default || c))
export const ThumbsupIcon = () => import('../../components/icons/ThumbsupIcon.vue' /* webpackChunkName: "components/thumbsup-icon" */).then(c => wrapFunctional(c.default || c))
export const TriangleRightIcon = () => import('../../components/icons/TriangleRightIcon.vue' /* webpackChunkName: "components/triangle-right-icon" */).then(c => wrapFunctional(c.default || c))
export const TwintIcon = () => import('../../components/icons/TwintIcon.vue' /* webpackChunkName: "components/twint-icon" */).then(c => wrapFunctional(c.default || c))
export const VisaIcon = () => import('../../components/icons/VisaIcon.vue' /* webpackChunkName: "components/visa-icon" */).then(c => wrapFunctional(c.default || c))
export const BlogOverviewPage = () => import('../../components/pages/BlogOverviewPage.vue' /* webpackChunkName: "components/blog-overview-page" */).then(c => wrapFunctional(c.default || c))
export const BlogPost = () => import('../../components/pages/BlogPost.vue' /* webpackChunkName: "components/blog-post" */).then(c => wrapFunctional(c.default || c))
export const ContactPage = () => import('../../components/pages/ContactPage.vue' /* webpackChunkName: "components/contact-page" */).then(c => wrapFunctional(c.default || c))
export const ContentPage = () => import('../../components/pages/ContentPage.vue' /* webpackChunkName: "components/content-page" */).then(c => wrapFunctional(c.default || c))
export const HomePage = () => import('../../components/pages/HomePage.vue' /* webpackChunkName: "components/home-page" */).then(c => wrapFunctional(c.default || c))
export const Product = () => import('../../components/pages/Product.vue' /* webpackChunkName: "components/product" */).then(c => wrapFunctional(c.default || c))
export const ProductCategory = () => import('../../components/pages/ProductCategory.vue' /* webpackChunkName: "components/product-category" */).then(c => wrapFunctional(c.default || c))
export const TeamMembersPage = () => import('../../components/pages/TeamMembersPage.vue' /* webpackChunkName: "components/team-members-page" */).then(c => wrapFunctional(c.default || c))
export const AllBlogs = () => import('../../components/shared/AllBlogs.vue' /* webpackChunkName: "components/all-blogs" */).then(c => wrapFunctional(c.default || c))
export const AppLink = () => import('../../components/shared/AppLink.vue' /* webpackChunkName: "components/app-link" */).then(c => wrapFunctional(c.default || c))
export const BlogCard = () => import('../../components/shared/BlogCard.vue' /* webpackChunkName: "components/blog-card" */).then(c => wrapFunctional(c.default || c))
export const BreadcrumbNavigation = () => import('../../components/shared/BreadcrumbNavigation.vue' /* webpackChunkName: "components/breadcrumb-navigation" */).then(c => wrapFunctional(c.default || c))
export const CategoryCard = () => import('../../components/shared/CategoryCard.vue' /* webpackChunkName: "components/category-card" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/shared/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const Container = () => import('../../components/shared/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c))
export const ContainerContent = () => import('../../components/shared/ContainerContent.vue' /* webpackChunkName: "components/container-content" */).then(c => wrapFunctional(c.default || c))
export const CookieConsentBanner = () => import('../../components/shared/CookieConsentBanner.vue' /* webpackChunkName: "components/cookie-consent-banner" */).then(c => wrapFunctional(c.default || c))
export const CornerButton = () => import('../../components/shared/CornerButton.vue' /* webpackChunkName: "components/corner-button" */).then(c => wrapFunctional(c.default || c))
export const CornerLink = () => import('../../components/shared/CornerLink.vue' /* webpackChunkName: "components/corner-link" */).then(c => wrapFunctional(c.default || c))
export const DocumentCard = () => import('../../components/shared/DocumentCard.vue' /* webpackChunkName: "components/document-card" */).then(c => wrapFunctional(c.default || c))
export const GoogleMap = () => import('../../components/shared/GoogleMap.vue' /* webpackChunkName: "components/google-map" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/shared/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductWideCard = () => import('../../components/shared/ProductWideCard.vue' /* webpackChunkName: "components/product-wide-card" */).then(c => wrapFunctional(c.default || c))
export const Provider = () => import('../../components/shared/Provider.vue' /* webpackChunkName: "components/provider" */).then(c => wrapFunctional(c.default || c))
export const ShoppingCartCounter = () => import('../../components/shared/ShoppingCartCounter.vue' /* webpackChunkName: "components/shopping-cart-counter" */).then(c => wrapFunctional(c.default || c))
export const SuggestedBlogPosts = () => import('../../components/shared/SuggestedBlogPosts.vue' /* webpackChunkName: "components/suggested-blog-posts" */).then(c => wrapFunctional(c.default || c))
export const TeamMember = () => import('../../components/shared/TeamMember.vue' /* webpackChunkName: "components/team-member" */).then(c => wrapFunctional(c.default || c))
export const TeamMembers = () => import('../../components/shared/TeamMembers.vue' /* webpackChunkName: "components/team-members" */).then(c => wrapFunctional(c.default || c))
export const TextLink = () => import('../../components/shared/TextLink.vue' /* webpackChunkName: "components/text-link" */).then(c => wrapFunctional(c.default || c))
export const ToTopButton = () => import('../../components/shared/ToTopButton.vue' /* webpackChunkName: "components/to-top-button" */).then(c => wrapFunctional(c.default || c))
export const YoutubeVideo = () => import('../../components/shared/YoutubeVideo.vue' /* webpackChunkName: "components/youtube-video" */).then(c => wrapFunctional(c.default || c))
export const CartProduct = () => import('../../components/shared/cart/CartProduct.vue' /* webpackChunkName: "components/cart-product" */).then(c => wrapFunctional(c.default || c))
export const CartSummary = () => import('../../components/shared/cart/CartSummary.vue' /* webpackChunkName: "components/cart-summary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAddress = () => import('../../components/shared/checkout/CheckoutAddress.vue' /* webpackChunkName: "components/checkout-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutConfirmationProduct = () => import('../../components/shared/checkout/CheckoutConfirmationProduct.vue' /* webpackChunkName: "components/checkout-confirmation-product" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewAddress = () => import('../../components/shared/checkout/CheckoutNewAddress.vue' /* webpackChunkName: "components/checkout-new-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSteps = () => import('../../components/shared/checkout/CheckoutSteps.vue' /* webpackChunkName: "components/checkout-steps" */).then(c => wrapFunctional(c.default || c))
export const ProductAccessories = () => import('../../components/shared/product/ProductAccessories.vue' /* webpackChunkName: "components/product-accessories" */).then(c => wrapFunctional(c.default || c))
export const ProductDescription = () => import('../../components/shared/product/ProductDescription.vue' /* webpackChunkName: "components/product-description" */).then(c => wrapFunctional(c.default || c))
export const ProductDocuments = () => import('../../components/shared/product/ProductDocuments.vue' /* webpackChunkName: "components/product-documents" */).then(c => wrapFunctional(c.default || c))
export const ProductMain = () => import('../../components/shared/product/ProductMain.vue' /* webpackChunkName: "components/product-main" */).then(c => wrapFunctional(c.default || c))
export const ProductMainVariants = () => import('../../components/shared/product/ProductMainVariants.vue' /* webpackChunkName: "components/product-main-variants" */).then(c => wrapFunctional(c.default || c))
export const ProductRelated = () => import('../../components/shared/product/ProductRelated.vue' /* webpackChunkName: "components/product-related" */).then(c => wrapFunctional(c.default || c))
export const ProductSpecification = () => import('../../components/shared/product/ProductSpecification.vue' /* webpackChunkName: "components/product-specification" */).then(c => wrapFunctional(c.default || c))
export const ProductCategoryFilter = () => import('../../components/shared/product-category/ProductCategoryFilter.vue' /* webpackChunkName: "components/product-category-filter" */).then(c => wrapFunctional(c.default || c))
export const ProductCategoryFilterOpen = () => import('../../components/shared/product-category/ProductCategoryFilterOpen.vue' /* webpackChunkName: "components/product-category-filter-open" */).then(c => wrapFunctional(c.default || c))
export const ProductCategoryPagination = () => import('../../components/shared/product-category/ProductCategoryPagination.vue' /* webpackChunkName: "components/product-category-pagination" */).then(c => wrapFunctional(c.default || c))
export const ProductCategorySidebar = () => import('../../components/shared/product-category/ProductCategorySidebar.vue' /* webpackChunkName: "components/product-category-sidebar" */).then(c => wrapFunctional(c.default || c))
export const BlogPostsSection = () => import('../../components/component-loader/BlogPostsSection.vue' /* webpackChunkName: "components/blog-posts-section" */).then(c => wrapFunctional(c.default || c))
export const CategoriesSection = () => import('../../components/component-loader/CategoriesSection.vue' /* webpackChunkName: "components/categories-section" */).then(c => wrapFunctional(c.default || c))
export const Downloads = () => import('../../components/component-loader/Downloads.vue' /* webpackChunkName: "components/downloads" */).then(c => wrapFunctional(c.default || c))
export const HeaderContent = () => import('../../components/component-loader/HeaderContent.vue' /* webpackChunkName: "components/header-content" */).then(c => wrapFunctional(c.default || c))
export const ImageSlider = () => import('../../components/component-loader/ImageSlider.vue' /* webpackChunkName: "components/image-slider" */).then(c => wrapFunctional(c.default || c))
export const ImageTextLinkSection = () => import('../../components/component-loader/ImageTextLinkSection.vue' /* webpackChunkName: "components/image-text-link-section" */).then(c => wrapFunctional(c.default || c))
export const ImageWithTextRight = () => import('../../components/component-loader/ImageWithTextRight.vue' /* webpackChunkName: "components/image-with-text-right" */).then(c => wrapFunctional(c.default || c))
export const JobCards = () => import('../../components/component-loader/JobCards.vue' /* webpackChunkName: "components/job-cards" */).then(c => wrapFunctional(c.default || c))
export const ProductsHigh = () => import('../../components/component-loader/ProductsHigh.vue' /* webpackChunkName: "components/products-high" */).then(c => wrapFunctional(c.default || c))
export const ProductsSection = () => import('../../components/component-loader/ProductsSection.vue' /* webpackChunkName: "components/products-section" */).then(c => wrapFunctional(c.default || c))
export const ProductsWide = () => import('../../components/component-loader/ProductsWide.vue' /* webpackChunkName: "components/products-wide" */).then(c => wrapFunctional(c.default || c))
export const ReferencesSection = () => import('../../components/component-loader/ReferencesSection.vue' /* webpackChunkName: "components/references-section" */).then(c => wrapFunctional(c.default || c))
export const TextContent = () => import('../../components/component-loader/TextContent.vue' /* webpackChunkName: "components/text-content" */).then(c => wrapFunctional(c.default || c))
export const TwoTextColumns = () => import('../../components/component-loader/TwoTextColumns.vue' /* webpackChunkName: "components/two-text-columns" */).then(c => wrapFunctional(c.default || c))
export const ComponentLoader = () => import('../../components/ComponentLoader.vue' /* webpackChunkName: "components/component-loader" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
