import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6bef8b6c = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _62856e36 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _7d9c04c3 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _fa14c7c0 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _52551922 = () => interopDefault(import('../pages/password-forgot.vue' /* webpackChunkName: "pages/password-forgot" */))
const _489bfb88 = () => interopDefault(import('../pages/ping.vue' /* webpackChunkName: "pages/ping" */))
const _0df76d2a = () => interopDefault(import('../pages/product-graphql-test.vue' /* webpackChunkName: "pages/product-graphql-test" */))
const _0d5f3939 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _7656ad3e = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _045028c1 = () => interopDefault(import('../pages/checkout/address.vue' /* webpackChunkName: "pages/checkout/address" */))
const _3e05ddb7 = () => interopDefault(import('../pages/checkout/building.vue' /* webpackChunkName: "pages/checkout/building" */))
const _236a8138 = () => interopDefault(import('../pages/checkout/confirmation.vue' /* webpackChunkName: "pages/checkout/confirmation" */))
const _7c9c75f6 = () => interopDefault(import('../pages/checkout/done.vue' /* webpackChunkName: "pages/checkout/done" */))
const _a2d17a14 = () => interopDefault(import('../pages/checkout/login.vue' /* webpackChunkName: "pages/checkout/login" */))
const _0ad1345a = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _a0253998 = () => interopDefault(import('../pages/account/order/_id/index.vue' /* webpackChunkName: "pages/account/order/_id/index" */))
const _1f1b8984 = () => interopDefault(import('../pages/password-reset/_id/_token/index.vue' /* webpackChunkName: "pages/password-reset/_id/_token/index" */))
const _5022c88e = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/konto",
    component: _6bef8b6c,
    name: "account"
  }, {
    path: "/warenkorb",
    component: _62856e36,
    name: "cart"
  }, {
    path: "/login",
    component: _7d9c04c3,
    name: "login"
  }, {
    path: "/logout",
    component: _fa14c7c0,
    name: "logout"
  }, {
    path: "/passwort-vergessen",
    component: _52551922,
    name: "password-forgot"
  }, {
    path: "/ping",
    component: _489bfb88,
    name: "ping"
  }, {
    path: "/product-graphql-test",
    component: _0df76d2a,
    name: "product-graphql-test"
  }, {
    path: "/registrieren",
    component: _0d5f3939,
    name: "register"
  }, {
    path: "/suche",
    component: _7656ad3e,
    name: "search"
  }, {
    path: "/checkout/adresse",
    component: _045028c1,
    name: "checkout-address"
  }, {
    path: "/checkout/objekt",
    component: _3e05ddb7,
    name: "checkout-building"
  }, {
    path: "/checkout/bestaetigen",
    component: _236a8138,
    name: "checkout-confirmation"
  }, {
    path: "/checkout/fertig",
    component: _7c9c75f6,
    name: "checkout-done"
  }, {
    path: "/checkout/login",
    component: _a2d17a14,
    name: "checkout-login"
  }, {
    path: "/checkout/zahlungsart",
    component: _0ad1345a,
    name: "checkout-payment"
  }, {
    path: "/konto/bestellung/:id",
    component: _a0253998,
    name: "account-order-id"
  }, {
    path: "/passwort-zuruecksetzen/:id/:token",
    component: _1f1b8984,
    alias: ["/passwort-setzen/:id/:token"],
    name: "password-reset-id-token"
  }, {
    path: "/*",
    component: _5022c88e,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
