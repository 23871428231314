import Vue from 'vue'
import { required, email, confirmed, min } from 'vee-validate/dist/rules'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from 'vee-validate'
import de from 'vee-validate/dist/locale/de.json'

localize('de', de)
localize({
  de: {
    names: {
      salutation: 'Anrede',
      firstname: 'Vorname',
      first_name: 'Vorname',
      lastname: 'Nachname',
      last_name: 'Nachname',
      company: 'Firma',
      phone: 'Telefon',
      email: 'E-Mail',
      username: 'E-Mail',
      message: 'Nachricht',
      newsletter: 'Newsletter',
      street: 'Strasse',
      postcode: 'PLZ',
      city: 'Ort',
      country: 'Land',
      type: 'Kundenart',
      password: 'Passwort',
      password_confirmation: 'Passwort bestätigen',
    },
  },
})

extend('min', min)
extend('email', email)
extend('required', required)
extend('confirmed', confirmed)
extend('phone', {
  validate: (value) => {
    // eslint-disable-next-line no-useless-escape
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
      value.replace(/\s/g, '')
    )
  },
  message: 'Keine gültige Telefonnummer.',
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
