import { render, staticRenderFns } from "./default.vue?vue&type=template&id=92d29b8a"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CookieConsentBanner: require('/vercel/path0/components/shared/CookieConsentBanner.vue').default,MainNavigationDesktop: require('/vercel/path0/components/shared/navigation/MainNavigationDesktop.vue').default,MainNavigationMobile: require('/vercel/path0/components/shared/navigation/MainNavigationMobile.vue').default,FooterNavigation: require('/vercel/path0/components/shared/footer/FooterNavigation.vue').default,ToTopButton: require('/vercel/path0/components/shared/ToTopButton.vue').default})
