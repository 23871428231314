export const state = () => ({
  cart: {},
})

export const mutations = {
  setCart(state, cart) {
    state.cart = cart
  },
}

export const actions = {
  async getCart({ commit }) {
    const { data: cart } = await this.$axios.$get('/shop/cart', {
      params: {
        guest_id: this.$auth.$storage.getUniversal('guest_id'),
      },
    })

    this.$auth.$storage.setUniversal('guest_id', cart.guest_id)

    commit('setCart', cart)
  },

  async linkGuestCart({ commit }) {
    const { data: cart } = await this.$axios.$post(
      '/shop/cart/link-guest-cart',
      {
        guest_id: this.$auth.$storage.getUniversal('guest_id'),
      }
    )

    this.$auth.$storage.setUniversal('guest_id', cart.guest_id)

    commit('setCart', cart)
  },

  async incrementQuantity({ commit }, entryId) {
    const { data: cart } = await this.$axios.$post(
      '/shop/cart/item/increment',
      {
        quantity: 0,
        entry_id: entryId,
        guest_id: this.$auth.$storage.getUniversal('guest_id'),
      }
    )

    commit('setCart', cart)
  },

  async decrementQuantity({ commit }, entryId) {
    const { data: cart } = await this.$axios.$post(
      '/shop/cart/item/decrement',
      {
        quantity: 0,
        entry_id: entryId,
        guest_id: this.$auth.$storage.getUniversal('guest_id'),
      }
    )

    commit('setCart', cart)
  },

  async setQuantity({ commit }, payload) {
    const { data: cart } = await this.$axios.$post('/shop/cart/item/set', {
      quantity: parseInt(payload.quantity),
      entry_id: payload.entryId,
      guest_id: this.$auth.$storage.getUniversal('guest_id'),
    })

    commit('setCart', cart)
  },

  async remove({ commit }, entryId) {
    const { data: cart } = await this.$axios.$post('/shop/cart/item/remove', {
      quantity: 0,
      entry_id: entryId,
      guest_id: this.$auth.$storage.getUniversal('guest_id'),
    })

    commit('setCart', cart)
  },
}

export const getters = {
  cart: (state) => state.cart,

  deliveryAddress: (state) => state.cart.deliveryAddress,

  priceTotal: (state, getters) => {
    if (!getters.products) {
      return 0
    }

    return getters.products.reduce(
      (total, product) => total + product.priceTotal,
      0
    )
  },

  grossPriceTotal: (state, getters) => {
    if (!getters.products) {
      return 0
    }

    return getters.products.reduce(
      (total, product) => total + product.gross_price_total,
      0
    )
  },

  totalTax: (state, getters) => {
    return getters.grossPriceTotal - getters.priceTotal
  },

  discounts: (state) => {
    return state.cart?.items?.filter((item) => item.productCode === 'discount')
  },

  products: (state) => {
    return state.cart?.items?.filter((item) => item.productCode === 'product')
  },

  remarks: (state) => {
    return state.cart?.remarks || ''
  },
}
