export const actions = {
  async nuxtServerInit({ commit, dispatch }) {
    await dispatch('global/loadGlobals')

    if (this.$auth.loggedIn || this.$auth.$storage.getUniversal('guest_id')) {
      await dispatch('cart/getCart')
    }

    if (this.$auth.$storage.getUniversal('priceInclTax') === undefined) {
      commit('global/setPriceInclTax', true)
    } else {
      commit(
        'global/setPriceInclTax',
        this.$auth.$storage.getUniversal('priceInclTax')
      )
    }
  },
}
