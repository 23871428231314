
import { mapGetters } from 'vuex'
import Vue100vh from 'vue-100vh'

export default {
  components: { Vue100vh },
  data() {
    return {
      firstLevelIndex: null,
      secondLevelIndex: null,
    }
  },

  computed: {
    ...mapGetters('nav', ['showMobileNav']),
    ...mapGetters('global', [
      'shopNavigation',
      'metaNavigation',
      'socialMedia',
    ]),
  },
}
