
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      query: this.$route.query?.suchbegriff || '',
    }
  },

  computed: {
    ...mapGetters('nav', ['showSearch']),
    ...mapGetters('search', ['pages', 'products', 'categories']),

    showOnlySearchInput() {
      return (
        this.pages === null &&
        this.products === null &&
        this.categories === null
      )
    },

    noResults() {
      return (
        this.pages?.nbHits === 0 &&
        this.products?.nbHits === 0 &&
        this.categories?.nbHits === 0
      )
    },
  },

  watch: {
    $route() {
      if (this.showSearch) {
        this.$store.commit('nav/closeSearch')
      }
    },
  },

  methods: {
    onSearch() {
      this.$store.dispatch('search/searchIndexes', this.query)
    },
  },
}
