import { render, staticRenderFns } from "./FooterNavigation.vue?vue&type=template&id=d869bfea"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterNavigationMain: require('/vercel/path0/components/shared/footer/FooterNavigationMain.vue').default,FooterNavigationMenu: require('/vercel/path0/components/shared/footer/FooterNavigationMenu.vue').default,FooterNavigationMeta: require('/vercel/path0/components/shared/footer/FooterNavigationMeta.vue').default})
