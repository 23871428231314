import { render, staticRenderFns } from "./NavSearch.vue?vue&type=template&id=0000045f"
import script from "./NavSearch.vue?vue&type=script&lang=js"
export * from "./NavSearch.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuCloseIcon: require('/vercel/path0/components/icons/MenuCloseIcon.vue').default,SearchIcon: require('/vercel/path0/components/icons/SearchIcon.vue').default,NavSearchPage: require('/vercel/path0/components/shared/navigation/NavSearchPage.vue').default,NavSearchCategory: require('/vercel/path0/components/shared/navigation/NavSearchCategory.vue').default,NavSearchProduct: require('/vercel/path0/components/shared/navigation/NavSearchProduct.vue').default})
