
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('search', ['query', 'products']),

    additionalProducts() {
      if (this.products.nbHits === 5) {
        return '1 weiterer Vorschlag'
      }

      const count = this.products.nbHits - 4

      return `${count} weitere Vorschläge`
    },

    results() {
      return this.products.hits.slice(0, 4)
    },
  },

  methods: {
    productNumber(product) {
      if (product.article_nr && product.product_nr) {
        return `${product.article_nr} <span class="px-1">|</span> ${product.product_nr}`
      }

      if (product.article_nr) {
        return product.article_nr
      }

      return product.product_nr
    },
  },
}
